<template>
    <layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">Edit Trainer</h4>
                        <form >
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label > Registration code</label>
                                        <input
                                                type="text"
                                                placeholder="T/0001/EID POC "
                                                class="form-control"
                                        />
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label >Name and Surname</label>
                                        <input
                                                type="text"
                                                placeholder="Name"
                                                class="form-control"
                                        />

                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label >Professional registration number  </label>
                                        <input
                                                type="text"
                                                placeholder="Professional registration number "
                                                class="form-control"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label > Date of last training </label>
                                        <input
                                                type="date"
                                                placeholder="Date of last training "
                                                class="form-control"
                                        />
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label >Length of training </label>
                                        <input
                                                type="text"
                                                placeholder="Length of training in days"
                                                class="form-control"
                                        />

                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label >Time worked as EID tester </label>
                                        <input
                                                type="text"
                                                placeholder="Time worked as EID tester "
                                                class="form-control"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label > Phone </label>
                                        <input
                                                type="number"
                                                placeholder="Phone"
                                                class="form-control"
                                        />
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label >Email</label>
                                        <input
                                                type="email"
                                                placeholder="Email"
                                                class="form-control"
                                        />

                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label >Job Title</label>
                                        <input
                                                type="text"
                                                placeholder="Job title "
                                                class="form-control"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label > Region </label>
                                        <multiselect v-model="value" :options="options" :multiple="false" placeholder="Select Region"></multiselect>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label >District</label>
                                        <input
                                                type="text"
                                                placeholder="District"
                                                class="form-control"
                                        />

                                    </div>
                                </div>
<!--                                <div class="col-md-4">-->
<!--                                    <div class="form-group">-->
<!--                                        <label >Name of facility</label>-->
<!--                                        <input-->
<!--                                                type="text"-->
<!--                                                placeholder="Name of facility "-->
<!--                                                class="form-control"-->
<!--                                        />-->
<!--                                    </div>-->
<!--                                </div>-->
                            </div>
                            <h4 class="card-title">Facility information </h4>
                            <div class="row mt-4">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label >Name of facility </label>
                                        <input
                                                type="text"
                                                placeholder="Name of facility"
                                                class="form-control"
                                        />
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label >Facility address</label>
                                        <input
                                                type="text"
                                                placeholder="Facility address"
                                                class="form-control"
                                        />

                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label >Type of testing site</label>
                                        <input
                                                type="text"
                                                placeholder="Type of testing site "
                                                class="form-control"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label >Head of testing site</label>
                                        <input
                                                type="text"
                                                placeholder="Head of testing site"
                                                class="form-control"
                                        />
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label >Phone</label>
                                        <input
                                                type="number"
                                                placeholder="Phone"
                                                class="form-control"
                                        />

                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label >Email</label>
                                        <input
                                                type="email"
                                                placeholder="Email "
                                                class="form-control"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label >Head of facility</label>
                                        <input
                                                type="text"
                                                placeholder="Head of facility"
                                                class="form-control"
                                        />
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label >Phone</label>
                                        <input
                                                type="number"
                                                placeholder="Phone"
                                                class="form-control"
                                        />

                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label >Email</label>
                                        <input
                                                type="email"
                                                placeholder="Email "
                                                class="form-control"
                                        />
                                    </div>
                                </div>
                            </div>
                            <button class="btn btn-primary" type="submit">Save</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="lodader__content" v-if="loader">
            <b-spinner style="width: 3rem; height: 3rem" class="loader" variant="success" label="Spinning"></b-spinner>
        </div>
    </layout>
</template>

<script>
    import Layout from "../../../layouts/main";
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";
    import Multiselect from "vue-multiselect";

    export default {
        page: {
            title: "Edit Trainer",
            meta: [{ name: "description", content: appConfig.description }]
        },
        components: { Layout,Multiselect,PageHeader },
        data() {
            return {
                title: "Edit Trainer",
                loader: false,
                items: [
                    {
                        text: "CQI",
                        href: "/"
                    },
                    {
                        text: "Edit trainer",
                        active: true
                    }
                ],

                options: [
                    "Adamawa ",
                    "Centre",
                    "East",
                    "Far North",
                    "Littoral",
                    "North",
                    "North West",
                    "West",
                    "South",
                    "South West",
                ],
                options1: [
                    "Assistant lab Tech ",
                    "QA lead",
                    "Focal Point",
                    "Other ",
                ],


                value:null,
                value1: null,
            };

        },
        validations: {

        },
        methods: {

        }
    };
</script>

<style scoped>
    .lodader__content{
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, .1);
        width: 100%;
        height: 100%;
    }

    .loader{
        position: relative;
        left: 50%;
        top: 50%;
    }
</style>